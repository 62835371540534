<template>
  <form @submit.prevent="submitAyudas" class="ayudasForm">
    <div class="text-end text-light">
      <Cerrar />
    </div>

    <div class="row">
      <h4 v-if="objAyuda.id" class="text-light text-center">
        Editando Registro
      </h4>
      <h4 v-else class="text-light text-center">Nuevo Registro</h4>
    </div>

    <div class="row mt-3">
      <div class="col-md-3" v-if="paginas">
        <label class="text-light">Pagina</label>
        <select
          class="form-select"
          v-model="objAyuda.pagina_id"
          :disabled="objAyuda.id"
        >
          <option v-for="p of paginas" v-bind:key="p.id" :value="p.id">
            {{ p.nombre }}
          </option>
        </select>
        <small class="form-text txt-red" v-if="v$.pagina_id.$error">
          *Debe seleccionar una pagina.
        </small>
      </div>
      <div class="col-md-4">
        <label class="text-light">Titulo</label>
        <input
          type="text"
          v-model="objAyuda.titulo"
          :class="{
            'form-control': true,
          }"
        />

        <small class="form-text txt-red" v-if="v$.titulo.$error">
          *Debe agregar un titulo.
        </small>
      </div>

      <div class="col-md-1">
        <label class="text-light">Orden</label>
        <select name="" id="" class="form-select" v-model="objAyuda.orden">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
        </select>
      </div>

      <div class="col-md-1">
        <div class="form-check form-switch">
          <input
            v-model="objAyuda.foto"
            class="form-check-input"
            type="checkbox"
            :checked="objAyuda.foto"
          />
          <label class="form-check-label text-light">Foto</label>
        </div>
      </div>

      <div class="col-md-1">
        <div class="form-check form-switch">
          <input
            v-model="objAyuda.status"
            class="form-check-input"
            type="checkbox"
            :checked="objAyuda.status"
          />
          <label class="form-check-label text-light">Activo</label>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-3">
        <img
          v-if="objAyuda.foto"
          :src="imageUrl || 'img/no-img.jpg'"
          alt=""
          id="img"
          class="img-fluid img-ayuda"
          @click="$refs.file.click()"
          style="cursor: pointer"
        />
        <input
          class="d-none"
          ref="file"
          type="file"
          accept=".png, .jpg, .jpeg"
          @change="onChange"
        />
      </div>
    </div>

    <GaleriaBtn />

    

    <div class="row">
      <div class="col-md-12 py-3">
        <CKEditor @sendContent="getContent" :content="objAyuda.contenido" />
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <button type="submit" class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onBeforeUnmount, ref } from "vue-demi";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CKEditor from "../../../components/CKEditor.vue";
import Cerrar from "../../../components/button/Cerrar.vue";
import useAyuda from "../composable/useAyuda";
import Swal from "sweetalert2";
import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";
export default {
  props: ["paginas"],
  components: {
    CKEditor,
    Cerrar,    
    GaleriaBtn
},
  setup() {
    const store = useStore();
    const editor = ref();
    const objAyuda = inject("objAyuda");
    const btn = inject("btn");
    const previewImage = ref("");
    const foto = ref("");
    const image = ref("");
    const imageUrl = inject("imageUrl");
    const idPagina = inject("idPagina");
    const { createAyuda, editAyuda , getAyuda } = useAyuda();
    const getContent = (val) => {
      objAyuda.contenido = val;
    };

    const rules = {
      titulo: {
        required,
      },
      pagina_id: {
        required,
      },
    };

    const v$ = useVuelidate(rules, objAyuda);
    const submitAyudas = async () => {
      store.commit("showLoader");
      v$.value.$touch();
      let data = "";

      if (v$.value.$invalid) {
        return false;
      }
      if (objAyuda.id) {
        idPagina.value = objAyuda.pagina_id;
        data = await editAyuda(objAyuda);
      } else {
        idPagina.value = objAyuda.pagina_id;
        data = await createAyuda(objAyuda);
      }
      getAyuda({ perPage: 0, pagina_id: idPagina.value });
      cerrar();
      store.commit("hideLoader");
      if (data.code == 200) {
        return Swal.fire("Excelente!", data.message, "success");
      } else {
         return Swal.fire("Error!", data.message, "error");
      }     
    };

    const cerrar = () => (btn.value = false);

    onBeforeUnmount(() => {
      objAyuda.id = null;
      objAyuda.pagina_id = null;
      objAyuda.titulo = "";
      objAyuda.contenido = "";
      objAyuda.foto = false;
      objAyuda.url = "";
      imageUrl.value = null;
      btn.value = false;
    });

    const onChange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        previewImage.value = e.target.result;
        objAyuda.url = previewImage.value;
      };
      image.value = file;
      imageUrl.value = URL.createObjectURL(file);
    };

    return {
      editor,
      objAyuda,
      submitAyudas,
      cerrar,
      getContent,
      onChange,
      image,
      foto,
      previewImage,
      imageUrl,
      v$,
    };
  },
};
</script>

<style scoped="scoped">
.ayudasForm {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
}
.img-ayuda {
  height: 150px !important;
  width: 200px;
}
</style>