import { computed } from "vue";
import { useStore } from "vuex";

const useAyuda = () => {
  const store = useStore();

  const createAyuda = async (ayuda) => {
    const resp = await store.dispatch("ayudas/createAyudaAsync", ayuda);
    return resp;
  };
  const editAyuda = async (ayuda) => {
    const resp = await store.dispatch("ayudas/editAyudaAsync", ayuda);
    return resp;
  };
  const deleteAyuda = async (ayuda) => {
    const resp = await store.dispatch("ayudas/deleteAyudaAsync", ayuda);
    return resp;
  };
  const getAyuda = async (ayuda) => {
    const resp = await store.dispatch("ayudas/getAyudasAsync", ayuda);
    return resp;
  };

  const getPaginas = async () => {
    const resp = await store.dispatch("ayudas/getPaginaAsync");
    return resp;
  };

  return {
    createAyuda,
    editAyuda,
    deleteAyuda,
    getAyuda,
    // findAyuda,
    getPaginas,
    lstAyudas: computed(() => store.getters["ayudas/getAyudas"]),
    lstPaginas: computed(() => store.getters["ayudas/getPaginas"]),
  };
};

export default useAyuda;
