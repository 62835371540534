<template>
  <label class="text-light">Página</label>
  <label class="text-light"></label>
  <div class="row">
    <div class="col-md-3" v-if="paginas">
      <select
        class="form-select"
        @change="filtrarPaginaAyuda"
        v-model="idPagina"
      >
        <option v-for="p of paginas" v-bind:key="p.id" :value="p.id">
          {{ p.nombre }}
        </option>
      </select>
    </div>
  </div>

  <div class="text-end mt-2">
    <button @click="agregarAyuda" class="myButtonAceptar">Agregar</button>
  </div>

  <div class="card mt-2 table-responsive-sm">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Pagina</th>
          <th>Titulo</th>
          <th>Orden</th>
          <th>Imagen</th>
          <th>Eliminar</th>
        </tr>
      </thead>
      <tbody v-for="a of ayudas" v-bind:key="a.id">
        <tr style="cursor: pointer">
          <td @click="select(a)">{{ a.pagina.nombre }}</td>
          <td @click="select(a)">{{ a.ayuda.titulo }}</td>
          <td @click="select(a)">{{ a.ayuda.orden }}</td>
          <td @click="select(a)">
            <img height="50" :src="getImg(a.ayuda.url)" />
          </td>
          <td>
            <button class="btnEliminarTable" @click="eliminarAyuda(a)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <h1></h1>
    <!-- <Paginador
      :data="ayudas.links"
      endpoint="/api/v1/getAyuda"
      set="setAyudas"
      :parametros="'&pagina_id='+idPagina"
    /> -->
  </div>
</template>

<script>
import { inject, onMounted, ref } from "vue-demi";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import useUtil from "../../../use/useUtil";
import useAyuda from "../composable/useAyuda";
// import Paginador from "../Paginador.vue";
export default {
  props: ["ayudas", "paginas"],
  components: {
    // Paginador,
  },
  mounted() {},
  setup() {
    const btn = inject("btn");
    const objAyuda = inject("objAyuda");
    const imageUrl = inject("imageUrl");
    const store = useStore();
    const ayudaFiltrada = ref([]);
    const idPagina = inject("idPagina");
    const { getImg } = useUtil();
    const { deleteAyuda, getAyuda } = useAyuda();    

    const agregarAyuda = () => {
      objAyuda.id = null;
      objAyuda.pagina_id = null;
      objAyuda.titulo = "";
      objAyuda.contenido = "";
      objAyuda.foto = false;
      objAyuda.url = "";
      objAyuda.orden = 1;
      imageUrl.value = "";
      btn.value = true;
    };

    const select = (e) => {
      objAyuda.id = e.ayuda.id;
      objAyuda.pagina_id = e.pagina.id;
      objAyuda.titulo = e.ayuda.titulo;
      objAyuda.contenido = e.ayuda.contenido;
      objAyuda.foto = e.ayuda.foto;
      objAyuda.deleted = e.ayuda.deleted;
      objAyuda.orden = e.ayuda.orden;
      (objAyuda.status = e.ayuda.status),
        (imageUrl.value = e.ayuda.url
          ? process.env.VUE_APP_URL_IMG + e.ayuda.url
          : null);
      btn.value = true;
    };

    const eliminarAyuda = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          store.commit("showLoader");
          objAyuda.id = e.ayuda.id;
          await deleteAyuda(objAyuda);
          getAyuda({ perPage: 0, pagina_id: idPagina.value });          
          btn.value = false;
          store.commit("hideLoader");
        }
      });
    };

    onMounted(() => {});

    const filtrarPaginaAyuda = async (e) => {
      store.commit("showLoader");
      idPagina.value = e.target.value;
      await getAyuda({ perPage: 0, pagina_id: idPagina.value });
      store.commit("hideLoader");
    };

    return {
      agregarAyuda,
      select,
      eliminarAyuda,
      // paginas,
      filtrarPaginaAyuda,
      ayudaFiltrada,
      idPagina,
      getImg,
    };
  },
};
</script>

<style>
</style>