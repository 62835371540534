<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Ayudas</h3>
    <div class="row">
      <div v-if="!btn" class="col-12">
        <AyudasTables v-if="lstAyudas && lstPaginas" :ayudas="lstAyudas" :paginas="lstPaginas" />
      </div>
      <div v-if="btn" class="col-12"><AyudasForm v-if="lstPaginas" :paginas="lstPaginas" /></div>
    </div>
  </div>
</template>

<script>
import { reactive, provide, inject, ref, onMounted } from "vue-demi";
import AyudasForm from "../components/AyudasForm.vue";
import AyudasTables from "../components/AyudasTables.vue";
import { useStore } from "vuex";
import useAyuda from "../composable/useAyuda";

export default {
  components: {
    AyudasTables,
    AyudasForm,
  },
  setup() {
    const store = useStore();
    const btn = inject("btn");
    const imageUrl = inject("imageUrl");
    const idPagina = ref(1);
    const { getAyuda, lstAyudas, getPaginas, lstPaginas } = useAyuda();
    const boxAyuda = reactive({
      activo: 0,
      inactivo: 0,
      total: 0,
      titulo: "Ayudas",
    });
    const objAyuda = reactive({
      id: null,
      pagina_id: null,
      titulo: "",
      contenido: "",
      foto: false,
      url: "",
      status: true,
      orden: 1,
      deleted: false,
    });

    onMounted(async () => {
      store.commit("showLoader");
      await getAyuda({ perPage: 0, pagina_id: 1 });
      await getPaginas();
      store.commit("hideLoader");
    });

    provide("objAyuda", objAyuda);
    provide("btn", btn);
    provide("imageUrl", imageUrl);
    provide("idPagina", idPagina);

    return { objAyuda, btn, boxAyuda, lstAyudas, lstPaginas };
  },
};
</script>

<style  scoped>
</style>

